import React, { useEffect } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'

export default function ExploreMore(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header
        title="Better Solutions"
        path={[
          { title: 'Better Solutions', to: '/sustainability/menu/better-solutions' },
          { title: 'Our goals going forward', to: '/sustainability/menu/better-solutions/more' },
        ]}
        backTo="/sustainability/menu/better-solutions"
      />

      <Animated animationIn="fadeInUp" animateOnMount>
        <p>
          <strong>
            Meeting the growing global market for household appliances without increasing environmental impact requires
            us to further optimize product performance and make better use of resources.
          </strong>
          <br />
          <br />
          <ul>
            <li>
              We create ever more efficient, high- performance appliances, which help consumers to live better lives,
              save money and reduce their environmental footprint.
            </li>
            <li>
              We will proactively contribute toward the circular economy by integrating a circular approach into our
              products and solutions.
            </li>
            <li>
              Our consumers can be reassured that we manage chemicals carefully and replace those that cause concern.
            </li>
          </ul>
        </p>
      </Animated>
    </div>
  )
}
