import React from 'react'
import { Link } from 'react-router-dom'
import YouTube from 'react-youtube'

import styles from './styles.module.scss'
// import sustainability from 'sources/videos/Sustainability.webm'

export default function Sustainability(props) {
  return (
    <Link className={styles.container} to="/sustainability/menu">
      <h1 className={styles.title}>Sustainability</h1>

      {/* <video className={styles.video} autoPlay onEnded={() => props.history.push('/')}>
        <source src={VIDEOS.video.sustainability || sustainability} type="video/webm" />
      </video> */}

      <YouTube
        containerClassName={styles.video}
        videoId="L3GJPTXRHts"
        opts={{
          height: '607',
          width: '1080',
          playerVars: {
            autoplay: 1,
            controls: 0,
            showinfo: 0,
            iv_load_policy: 3,
          },
        }}
        onEnd={() => props.history.push('/')}
      />

      <div className={styles.trigger}>
        <div className={styles.hand} />
      </div>
    </Link>
  )
}
