import React, { useEffect } from 'react'
import Header from 'components/Header'
import { Animated } from 'react-animated-css'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import { ReactComponent as IconCarbonEmissions } from 'sources/images/icon-carbon-emissions.svg'
import { ReactComponent as IconOperations } from 'sources/images/icon-operations.svg'
import { ReactComponent as IconValueChain } from 'sources/images/icon-value-chain.svg'

export default function OurClimateTargets(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header
        title="Our Climate Targets"
        path={[{ title: 'Climate targets', to: '/sustainability/menu/our-climate-targets' }]}
        backTo="/sustainability/menu"
      />

      <p className={styles.phrase}>
        By the end of 2020, we reduced our absolute scope 1 and 2 greenhouse gas emissions by 70% compared to 2015 –
        toward our 80% reduction target by 2025 and our 100% reduction by 2030.
      </p>

      <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
        <div className={styles.infoBlock}>
          <IconCarbonEmissions />
          <span>
            Science Based Target: Reduce
            <br />
            carbon emissions in operations
            <br />
            by 80% and by 25% in products
            <br />
            between 2015 and 2025
          </span>
        </div>
      </Animated>

      <Animated animationIn="fadeInUp" animateOnMount animationInDelay={750}>
        <div className={styles.infoBlock}>
          <IconValueChain />
          <span>
            United Nations Global
            <br />
            Compact Business ambition
            <br />
            for 1.5 °C - climate neutral
            <br />
            value chain by 2050
          </span>
        </div>
      </Animated>

      <Animated animationIn="fadeInUp" animateOnMount animationInDelay={1000}>
        <div className={styles.infoBlock}>
          <IconOperations />
          <span>
            Climate neutral
            <br />
            operations by 2030
          </span>
        </div>
      </Animated>
    </div>
  )
}
