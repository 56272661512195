import React, { Component } from 'react'
import { Switch, withRouter } from 'react-router'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import cn from 'classnames'

import styles from './styles.module.scss'

class RouteSlider extends Component {
  state = {
    forward: true,
  }

  calcPathDepth = location => {
    return location.pathname === '/' ? 1 : location.pathname.split('/').length
  }

  componentWillReceiveProps = nextProps => {
    const prevDepth = this.calcPathDepth(this.props.location)
    const nextDepth = this.calcPathDepth(nextProps.location)

    this.setState({ forward: nextDepth > prevDepth })
  }

  render = () => {
    const { forward } = this.state

    return (
      <TransitionGroup className={cn(styles.group, forward ? styles.forward : styles.ahead)}>
        <CSSTransition
          key={this.props.location.pathname}
          classNames={{
            enter: styles.enter,
            exit: styles.exit,
          }}
          timeout={500}
        >
          <Switch location={this.props.location}>{this.props.children}</Switch>
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

export default withRouter(RouteSlider)
