import React, { useEffect } from 'react'
import { Animated } from 'react-animated-css'
import { Link } from 'react-router-dom'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'

export default function ExploreMore(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header
        title="Better Living"
        path={[
          { title: 'Better Society', to: '/sustainability/menu/better-living' },
          { title: 'Our goals going forward', to: '/sustainability/menu/better-living/more' },
        ]}
        backTo="/sustainability/menu/better-living"
      />

      <Animated animationIn="fadeInUp" animateOnMount>
        <p>
          <strong>
            We aim to shape better and more sustainable living around the world by promoting better eating, better
            garment care and better home environments.
          </strong>
          <br />
          <br />

          <ul>
            <li>
              By positively influencing how our products are used, we can reduce food waste, promote resource efficient
              food choices and nutrition, as well as enhance sustainable eating experiences.
            </li>
            <li>
              As a leading laundry appliance manufacturer, we can further contribute to more sustainable laundry
              practices by incorporating innovative solutions and promoting modern laundry habits that increase laundry
              efficiency and improve garment care.
            </li>
            <li>
              We offer consumers air, water and floor products and solutions that promote wellbeing in the home by
              making indoor environments healthier and caring for them more sustainably.
            </li>
          </ul>

          <Link to="/sustainability/menu/better-living/more/videos" className={styles.button}>
            Sustainability
          </Link>
        </p>
      </Animated>
    </div>
  )
}
