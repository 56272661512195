import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import chiefHead from 'sources/images/chief.png'
import hand from 'sources/images/hand.png'
import ship from 'sources/images/ship.png'
import waves from 'sources/images/waves.png'

export default function BetterSociety(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header
        title="Better Living"
        path={[{ title: 'Better Living', to: '/sustainability/menu/better-living' }]}
        backTo="/sustainability/menu"
        dark
      />

      <div className={styles.block}>
        <div className={styles.chief}>
          <img src={chiefHead} className={styles.chiefHead} alt="Chief" />
          <span className={styles.chiefMouth} />
        </div>
        <p>
          Make sustainable eating the preferred
          <br />
          choice
        </p>
      </div>

      <div className={styles.block}>
        <div className={styles.handFinger}>
          <img src={hand} alt="Hand" />
          <span className={styles.ring1} />
          <span className={styles.ring2} />
          <span className={styles.ring3} />
        </div>
        <p style={{ marginTop: 25, marginBottom: 45 }}>
          Make clothes last twice as long with half the
          <br />
          environmental impact
        </p>
      </div>

      <div className={styles.block}>
        <div className={styles.shipOnWaves}>
          <img src={ship} className={styles.ship} alt="Ship" />
          <img src={waves} className={styles.waves} alt="Waves" />
        </div>
        <p style={{ marginTop: 9 }}>
          Make homes healthier and more sustainable
          <br />
          through smart solutions for air, water and floors.
        </p>
      </div>

      <Link to="/sustainability/menu/better-living/more" className={styles.more}>
        Explore more <span className="fa">&#xf054;</span>
      </Link>
    </div>
  )
}
