import React from 'react'
import { Router, Route } from 'react-router'
import { Provider } from 'react-redux'
import history from 'utils/history'
import store from 'store'
import RouteSlider from 'components/RouteSlider'

import Home from '../Home'
import Sustainability from '../Sustainability'
import Menu from '../Menu'
import OurClimateTargets from '../OurClimateTargets'
import OurClimateTargetsExploreMore from '../OurClimateTargets/More'
import BetterSolutions from '../BetterSolutions'
import BetterSolutionsExploreMore from '../BetterSolutions/More'
import BetterOperations from '../BetterOperations'
import BetterOperationsExploreMore from '../BetterOperations/More'
import BetterSociety from '../BetterSociety'
import BetterSocietyExploreMore from '../BetterSociety/More'
import BetterSocietyVideos from '../BetterSociety/Videos'

import styles from './styles.module.scss'

history.replace('/')

export default function App(props) {
  return (
    <div className={styles.wrapper}>
      <Provider store={store}>
        <Router history={history}>
          <RouteSlider>
            <Route path="/" component={Home} exact />
            <Route path="/sustainability" component={Sustainability} exact />
            <Route path="/sustainability/menu" component={Menu} exact />
            <Route path="/sustainability/menu/our-climate-targets" component={OurClimateTargets} exact />
            <Route
              path="/sustainability/menu/our-climate-targets/more"
              component={OurClimateTargetsExploreMore}
              exact
            />
            <Route path="/sustainability/menu/better-solutions" component={BetterSolutions} exact />
            <Route path="/sustainability/menu/better-solutions/more" component={BetterSolutionsExploreMore} exact />
            <Route path="/sustainability/menu/better-company" component={BetterOperations} exact />
            <Route path="/sustainability/menu/better-company/more" component={BetterOperationsExploreMore} exact />
            <Route path="/sustainability/menu/better-living" component={BetterSociety} exact />
            <Route path="/sustainability/menu/better-living/more" component={BetterSocietyExploreMore} exact />
            <Route path="/sustainability/menu/better-living/more/videos" component={BetterSocietyVideos} exact />
          </RouteSlider>
        </Router>
      </Provider>
    </div>
  )
}
