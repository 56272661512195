import history from 'utils/history'

let timeOut = null

export function set() {
  unset()

  console.log('set idle')
  timeOut = setTimeout(() => {
    history.push('/')
  }, 120000)
}

export function unset() {
  console.log('unset idle')
  clearTimeout(timeOut)
}
