import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'
import YouTube from 'react-youtube'
import Slider from 'react-slick'
import cn from 'classnames'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
// import Replate from 'sources/videos/Replate.webm'
// import SustainabilityBrief from 'sources/videos/SustainabilityBrief.webm'
// import { VIDEOS } from 'containers/Home'

export default class Videos extends Component {
  state = {
    index: 0,
  }
  players = []
  slides = [{ title: 'Sustainability in brief', src: 'Yrqg-oPnl_k' }, { title: 'Replate.com', src: 'KTzV4vyjFMU' }]

  componentDidMount = () => {
    idleActions.unset()
    this.onChangeIndex(0)
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.state.index !== nextState.index
  }

  onChangeIndex = index => {
    console.log('onChangeIndex', index)
    console.log('this.players', this.players)

    this.players[this.state.index].internalPlayer.pauseVideo()

    this.setState({ index }, () => {
      this.slider.slickGoTo(this.state.index)
      this.players[this.state.index].internalPlayer.playVideo()
    })
  }

  onNextSlide = () => {
    const { index } = this.state

    if (index < this.slides.length - 1) {
      this.onChangeIndex(index + 1)
    } else {
      // this.onChangeIndex(0)
      this.props.history.push('/sustainability/menu/better-living/more')
    }
  }

  render = () => {
    const { index } = this.state

    return (
      <div className={styles.container} onClick={this.onNextSlide}>
        <Header
          title={this.slides[index].title}
          path={[
            { title: 'Better Society', to: '/sustainability/menu/better-living' },
            { title: 'Our goals going forward', to: '/sustainability/menu/better-living/more' },
            { title: 'Videos', to: '/sustainability/menu/better-living/videos' },
          ]}
          backTo="/sustainability/menu/better-living/more"
        />

        <Animated animationIn="fadeInDown" animateOnMount>
          <Slider
            infinite={false}
            swipe={true}
            className={styles.slider}
            arrows={false}
            afterChange={this.onChangeIndex}
            ref={node => (this.slider = node)}
          >
            {this.slides.map((slide, i) => (
              <div className={styles.slide} key={i}>
                {/* <video
                  onClick={this.onClickVideo}
                  ref={node => (this.players[i] = node)}
                  className={styles.video}
                  preload="none"
                  onEnded={this.onNextSlide}
                >
                  <source src={slide.src} type="video/webm" />
                </video> */}

                <YouTube
                  ref={node => (this.players[i] = node)}
                  containerClassName={styles.video}
                  videoId={slide.src}
                  opts={{
                    height: '607',
                    width: '1080',
                    playerVars: {
                      autoplay: 0,
                      controls: 0,
                      showinfo: 0,
                      iv_load_policy: 3,
                    },
                  }}
                  onEnd={this.onNextSlide}
                />
              </div>
            ))}
          </Slider>
        </Animated>

        <Animated animationIn="fadeInUp" animateOnMount>
          <div className={styles.sliderDots}>
            {this.slides.map((slide, i) => (
              <div className={cn(index === i && styles.activeDot)} onClick={() => this.slider.slickGoTo(i)} key={i} />
            ))}
          </div>
        </Animated>
      </div>
    )
  }
}
