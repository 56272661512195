import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
// import { message } from 'antd'

import styles from './styles.module.scss'
// import sustainability from 'sources/videos/Sustainability.mp4'
// import FoodFoundation from 'sources/videos/FoodFoundation.mp4'
// import FoodHeroes from 'sources/videos/FoodHeroes.mp4'

// const toUpload = {
//   sustainability,
//   FoodFoundation,
//   FoodHeroes,
// }

class Videos {
  // constructor() {
  //   this.init()
  // }

  _video = {}

  async init() {
    // let msg = message.loading('Downloading ...', 0)

    try {
      // for (const videoName of Object.keys(toUpload)) {
      //   console.info('Downloading', videoName, toUpload[videoName])
      //   const video = await fetch(toUpload[videoName])
      //   const file = await video.blob()
      //   console.info('Done')

      //   this._video[videoName] = URL.createObjectURL(file)
      // }

      console.log('Ready')
      // message.success('All videos are downloaded', 10)
    } catch (e) {
      console.error(e)
      // message.error(e.message, 10)
    } finally {
      // msg()
    }
  }

  get video() {
    return this._video
  }
}

export const VIDEOS = new Videos()

export default function Home(props) {
  let timeout

  // const iniTimeout = () => {
  //   timeout = setTimeout(() => {
  //     props.history.push('/sustainability')
  //   }, 30000)
  // }

  // iniTimeout()

  // useEffect(() => {
  //   return () => {
  //     if (timeout) clearTimeout(timeout)
  //   }
  // })

  return (
    <Link className={styles.container} to="/sustainability/menu">
      <h1 className={styles.title}>Sustainability</h1>

      <div className={styles.trigger}>
        <div className={styles.hand} />
      </div>
    </Link>
  )
}
