import React, { useEffect } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'

export default function ExploreMore(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header
        title="Better Company"
        path={[
          { title: 'Better Company', to: '/sustainability/menu/better-company' },
          { title: 'Our goals going forward', to: '/sustainability/menu/better-company/more' },
        ]}
        backTo="/sustainability/menu/better-company"
      />

      <Animated animationIn="fadeInUp" animateOnMount>
        <p>
          <strong>
            We work continuously to be more resource efficient, and become a safer, diverse and more ethical company –
            both in our own operations and throughout our supply chain.
          </strong>
          <br />
          <br />
          <ul>
            <li>We are reducing our own footprint by running efficient operations all around the world.</li>
            <li>
              We continue to build an ethical, diverse, inclusive and trusted company, where everyone impacted by our
              operations can feel confident that their rights are respected.
            </li>
            <li>
              Our products are to be made in the same way throughout our global supply chain – with respect for the
              people who made them and care for the environment.
            </li>
          </ul>
        </p>
      </Animated>
    </div>
  )
}
