import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import history from 'utils/history'

import styles from './styles.module.scss'
import homeIcon from 'sources/images/home-breadcrumb.png'
import homeBlueIcon from 'sources/images/home-blue-breadcrumb.png'

function Header(props) {
  const { title, backTo, dark, offsetTop, subTitle, metric, hideHome = false } = props

  const onClickBack = e => {
    e.stopPropagation()

    history.push(backTo)
  }

  const onClickHome = e => {
    e.preventDefault()
    e.stopPropagation()

    history.push('/sustainability/menu')
  }

  return (
    <div className={cn(styles.header, dark && styles.dark)}>
      {!hideHome && (
        <nav>
          <a href="/sustainability/menu" onClick={onClickHome} className={styles.home}>
            <img src={dark ? homeBlueIcon : homeIcon} alt="home" />
          </a>
          {/* {path.map((link, i) => (
          <Fragment key={i}>
            <span className={styles.delimiter} />
            <NavLink to={link.to}>{link.title}</NavLink>
          </Fragment>
        ))} */}
        </nav>
      )}

      <div className={styles.bottom} style={{ top: offsetTop || 0 }}>
        <span className={styles.back} onClick={onClickBack} />
        <h1 className={cn(styles.title, metric && styles.titleMetric)}>
          {title}
          {subTitle && <h2>{subTitle}</h2>}
        </h1>
      </div>
    </div>
  )
}

Header.propTypes = {
  path: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, to: PropTypes.string })).isRequired,
  title: PropTypes.string.isRequired,
  backTo: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  offsetTop: PropTypes.number,
  subTitle: PropTypes.string,
  metric: PropTypes.bool,
  hideHome: PropTypes.bool,
}

export default Header
