import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'

export default function Menu(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      {/* <h2 className={styles.title}>Sustainability</h2> */}
      <Header
        title="Sustainability"
        path={[{ title: 'Sustainability', to: '/sustainability/menu' }]}
        backTo="/"
        hideHome
        offsetTop={-75}
      />

      <p>
        <h4>For the Better 2030</h4>
        Our sustainability framework – For the Better 2030 – defines how Electrolux works to achieve Better Company,
        Better Solutions and Better Living. It includes our most important sustainability issues and helps us strive
        toward our purpose to shape living for the better.
      </p>

      <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
        <ul className={styles.menu}>
          <li>
            <Link to="/sustainability/menu/our-climate-targets">Our Climate Targets</Link>
          </li>
          <li>
            <Link to="/sustainability/menu/better-company">Better Company</Link>
          </li>
          <li>
            <Link to="/sustainability/menu/better-solutions">Better Solutions</Link>
          </li>
          <li>
            <Link to="/sustainability/menu/better-living">Better Living</Link>
          </li>
        </ul>
      </Animated>

      <span className={styles.fullStory}>
        The full story at:
        <br />
        electroluxgroup.com/en/electrolux-sustainability-report-2020-32562/
      </span>
    </div>
  )
}
