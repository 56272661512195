import React, { useEffect } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'

export default function ExploreMore(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header
        title="Our climate targets"
        path={[
          { title: 'Climate targets', to: '/sustainability/menu/our-climate-targets' },
          { title: 'Explore more', to: '/sustainability/menu/our-climate-targets/more' },
        ]}
        backTo="/sustainability/menu/our-climate-targets"
      />

      <Animated animationIn="fadeInUp" animateOnMount>
        <p>
          We’re dedicated to reducing our own carbon footprint, as well as creating sustainable experiences both for
          consumers and for the health of our planet.
          <br />
          <br />
          To do this, we’re working towards two ambitious targets* for 2025 in support of the Paris Climate Agreement:
          <br />
          <br />
          •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reduce greenhouse gas emissions from operations by 80%
          <br />
          <br />
          •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reduce emissions from products by 25%
          <br />
          <br />
          *Both targets have been approved by the Science Based Targets initiative. The baseline is 2015.
        </p>
      </Animated>
    </div>
  )
}
