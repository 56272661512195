import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Animated } from 'react-animated-css'
import CountUp from 'react-countup'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import cloudImg from 'sources/images/percent-cloud.jpg'

export default function BetterSolutions(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header
        title="Better Solutions"
        path={[{ title: 'Better Solutions', to: '/sustainability/menu/better-solutions' }]}
        backTo="/sustainability/menu"
        dark
      />

      <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
        <div className={styles.statBlock}>
          <span>
            <CountUp end={36} duration={3} start={0} useEasing delay={0.8} />%
          </span>
          <p>
            Our most energy and water efficient products
            <br />
            accounted for 26% of total units sold and
            <br />
            36% of gross profit in 2020
          </p>
        </div>
      </Animated>

      <Animated animationIn="fadeInUp" animateOnMount animationInDelay={750}>
        <div className={styles.statBlock}>
          <span>
            <CountUp end={6800} duration={3} start={6700} useEasing delay={1.1} /> metric tons
          </span>
          <p>
            We used 6,800 metric tons of recycled plastic
            <br />
            in our products in 2020
          </p>
        </div>
      </Animated>

      <Animated animationIn="fadeInUp" animateOnMount animationInDelay={1000}>
        <div className={styles.statBlock}>
          <span>
            <img src={cloudImg} alt="Cloud" />
            <span>web</span>
          </span>
          <p>
            Our Eco@web chemical management tool
            <br />
            was rolled out in Asia-Pacific and Egypt
          </p>
        </div>
      </Animated>

      <Link to="/sustainability/menu/better-solutions/more" className={styles.more}>
        Explore more <span className="fa">&#xf054;</span>
      </Link>
    </div>
  )
}
