import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Animated } from 'react-animated-css'
import CountUp from 'react-countup'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import refreshImg from 'sources/images/refresh.jpg'

export default function BetterCompany(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header
        title="Better Company"
        path={[{ title: 'Better Operations', to: '/sustainability/menu/better-company' }]}
        backTo="/sustainability/menu"
        dark
      />

      <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
        <div className={styles.statBlock}>
          <div>
            <img src={refreshImg} alt="90%" />
            <span>
              <CountUp end={70} duration={3} start={0} useEasing delay={0.8} />%
            </span>
          </div>
          <p>
            –70% absolute reduction of our CO2
            <br />
            emissions in our operations since 2015
          </p>
        </div>
      </Animated>

      <Animated animationIn="fadeInUp" animateOnMount animationInDelay={750}>
        <div className={styles.statBlock}>
          <div>
            <img src={refreshImg} alt="90%" />
            <span>
              <CountUp end={79} duration={3} start={0} useEasing delay={1.1} />%
            </span>
          </div>
          <p>
            79% of employees completed our
            <br />
            anti-corruption training
          </p>
        </div>
      </Animated>

      <Animated animationIn="fadeInUp" animateOnMount animationInDelay={1000}>
        <div className={styles.statBlock}>
          <div>
            <img src={refreshImg} alt="90%" />
            <span>
              <CountUp end={74} duration={3} start={0} useEasing delay={1.3} />%
            </span>
          </div>
          <p>
            74% of our top direct material suppliers have
            <br />
            committed to disclose their carbon emissions
            <br />
            and set targets
          </p>
        </div>
      </Animated>

      <Link to="/sustainability/menu/better-company/more" className={styles.more}>
        Explore more <span className="fa">&#xf054;</span>
      </Link>
    </div>
  )
}
